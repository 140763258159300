import React, { useState, useEffect } from "react";

// Inline styles
const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "20px",
    maxWidth: "400px",
    margin: "auto",
    backgroundColor: "#f0f0f0",
    borderRadius: "8px",
    boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
  },
  select: {
    padding: "10px",
    margin: "5px 0",
    borderRadius: "5px",
    border: "1px solid #ccc",
  },
  input: {
    padding: "5px",
    margin: "5px 0",
    width: "calc(100% - 22px)", // Accounting for padding and border
    borderRadius: "5px",
    border: "1px solid #ccc",
  },
  button: {
    padding: "10px 20px",
    margin: "10px 0",
    backgroundColor: "#007bff",
    color: "white",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
  },
  fieldContainer: {
    width: "100%",
    marginBottom: "10px",
    marginTop: "10px",
  },
  label: {
    marginBottom: "5px",
    display: "block",
  },
};

const primitiveType = [
  "boolean",
  "date",
  "dateTime",
  "double",
  "duration",
  "float",
  "integer",
  "long",
  "string",
  "time",
];
// CommandPanel Component
const CommandPanel = ({ selectedObject, allSchemas }) => {
  const [selectedCommand, setSelectedCommand] = useState("");
  const [commands, setCommands] = useState([]);
  const [currentObjectSchema, setCurrentObjectSchema] = useState(null);
  const [currentCommandSchema, setCurrentCommandSchema] = useState(null);
  const [requestPayload, setRequestPayload] = useState({});

  const findSchemaById = (schemaId) => {
    return allSchemas.find((schema) => schema["@id"] === schemaId);
  };

  useEffect(() => {
    if (selectedObject) {
      const schema = findSchemaById(selectedObject["$metadata"]["$model"]);
      setCurrentObjectSchema(() => schema);
      console.log(schema);
      const commands = schema?.contents.filter(
        (content) => content["@type"] === "Command"
      );
      if (commands) {
        setCommands(() => commands);
      } else {
        setCommands(() => []);
      }
    }
  }, [selectedObject]);

  const handleCommandChange = (e) => {
    setSelectedCommand(e.target.value);
    var commandSchema = commands.filter((s) => s.name === e.target.value);
    if (commandSchema) {
      setCurrentCommandSchema(() => commandSchema[0]);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let convertedValue = value;
    // Find the field schema to determine its type
    let fieldSchema = "";
    if (
      currentCommandSchema.request &&
      "@type" in currentCommandSchema.request &&
      currentCommandSchema.request["@type"] === "Object"
    ) {
      const field = currentCommandSchema?.request?.fields?.find(
        (field) => field.name === name
      );
      if (field) {
        fieldSchema = field[0].schema;
      }
    } else {
      fieldSchema = currentCommandSchema.request.schema;
    }

    if (fieldSchema) {
      switch (fieldSchema) {
        case "double":
        case "float":
        case "long":
          convertedValue = parseFloat(value);
          convertedValue = isNaN(convertedValue) ? 0 : convertedValue;
          break;
        case "integer":
          convertedValue = parseInt(value, 10);
          break;
        case "boolean":
          convertedValue = value === "true";
          break;
        case "dateTime":
          // Assuming ISO 8601 format; no conversion needed as it's already a string
          break;
        // Handle additional schema types as needed
        default:
          // For string and other unhandled types; no conversion needed
          break;
      }
    }

    // Update the request payload with the converted value
    setRequestPayload((prev) => ({ ...prev, [name]: convertedValue }));
  };

  const executeCommand = async (e) => {
    e.preventDefault();
    console.log(
      `Executing command: ${selectedCommand} with payload:`,
      requestPayload
    );
    // Command execution logic goes here
    const apiUrl = `${process.env.REACT_APP_VIA_OBJECT_API_BASE}/api/object/command/execute`;
    const requestBody = {
      instanceId: selectedObject["$dtId"], // Assuming selectedCommand holds the DTDL ID; adjust as necessary
      commandName: selectedCommand, // This should be the actual command name; adjust variable as necessary
      parameters: { ...requestPayload }, // Cloning requestPayload to parameters
    };
    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log("Command execution successful:", data);
      // Handle successful command execution here (e.g., show a notification)
    } catch (error) {
      console.error("Error executing command:", error);
      // Handle errors here (e.g., show an error message)
    }
  };

  const renderInputField = (field) => {
    if (!field) {
      return;
    }

    let fieldCustomSchema;
    if (currentObjectSchema.schemas) {
      fieldCustomSchema = currentObjectSchema?.schemas?.filter(
        (s) => s["@id"] === field.schema
      );
    }
    if (field.schema === "dateTime") {
      return (
        <input
          style={styles.input}
          type="datetime-local"
          name={field.name}
          onChange={handleInputChange}
        />
      );
    } else if (field.schema === "double" || field.schema === "integer") {
      return (
        <input
          style={styles.input}
          type={"number"}
          name={field.name}
          onChange={handleInputChange}
        />
      );
    } else if (field.schema === "string") {
      return (
        <input
          style={styles.input}
          type={"text"}
          name={field.name}
          onChange={handleInputChange}
        />
      );
    } else if (field.schema === "boolean") {
      return (
        <>
          {" "}
          <select
            name={field.name}
            style={styles.select}
            onChange={handleInputChange}
          >
            <option value="">Select a value</option>
            <option value="true">True</option>
            <option value="false">False</option>
          </select>
        </>
      );
    } else if (
      field.schema["@type"] === "Enum" &&
      field.schema.hasOwnProperty("enumValues")
    ) {
      return (
        <select name={field.name} onChange={handleInputChange}>
          {field.schema.enumValues.map((enumValue) => (
            <option key={enumValue.enumValue} value={enumValue.enumValue}>
              {enumValue.name}
            </option>
          ))}
        </select>
      );
    } else if (fieldCustomSchema && fieldCustomSchema.length > 0) {
      if (fieldCustomSchema[0]["@type"] === "Enum") {
        return (
          <select name={field.name} onChange={handleInputChange}>
            <option value="">Select a value</option>
            {fieldCustomSchema[0].enumValues.map((enumValue) => (
              <option key={enumValue.enumValue} value={enumValue.enumValue}>
                {enumValue.name}
              </option>
            ))}
          </select>
        );
      }
    }
    // Additional field types can be handled here
  };

  const renderRequestForm = (command) => {
    if (command) {
      if (
        command.request &&
        command.request.schema &&
        command.request.schema["@type"] === "Object"
      ) {
        return command.request.schema.fields.map((field, index) => (
          <div style={styles.container} key={index}>
            <label style={styles.label}>{field.name}: </label>
            {renderInputField(field)}
          </div>
        ));
      } else {
        // Simple property case
        return (
          <div style={styles.fieldContainer}>
            {command.request ? (
              <label>{command.request.name} </label>
            ) : (
              <label>{command.name} </label>
            )}
            {renderInputField(command.request)}
          </div>
        );
      }
    }
  };

  return (
    <form onSubmit={executeCommand}>
      <select
        style={styles.select}
        onChange={handleCommandChange}
        value={selectedCommand}
      >
        <option value="">Select a Command</option>
        {commands.map((command, index) => (
          <option key={index} value={command.name}>
            {command.name}
          </option>
        ))}
      </select>
      {selectedCommand && selectedCommand !== "" && (
        <>
          {renderRequestForm(
            commands.find((cmd) => cmd.name === selectedCommand)
          )}
          <button type="submit" className="save-button">
            Execute
          </button>
        </>
      )}
    </form>
  );
};

export default CommandPanel;
