import React, { useState, useEffect, useRef } from 'react';
import "./PropertyPanel.css";

const PropertiesEditor = ({ selectedObject, schemas }) => {
    const [formState, setFormState] = useState({});
    const [objectProperties, setObjectProperties] = useState([]);
    const formRef = useRef();
    const inputRefs = useRef(null);
    let formFields = useRef();
    const getAllProperties = (schema, allSchemas) => {
        let properties = [];

        // Direct properties from the current schema
        const directProperties = schema.contents.filter(item => item['@type'] === 'Property' || item['@type'] === 'Telemetry');
        properties = properties.concat(directProperties);

        // Recursively add properties from extended schemas
        if (schema.extends) {
            schema.extends.forEach(extendId => {
                const parentSchema = allSchemas.find(s => s['@id'] === extendId);
                if (parentSchema) {
                    var parentSchemaProperties = getAllProperties(parentSchema, allSchemas);
                    properties = parentSchemaProperties.concat(properties);
                }
            });
        }

        return properties;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormState(prevState => ({
            ...prevState,
            [name]: value,
        }));
    };
    

    useEffect(() => {
        const selectedSchema = schemas.filter(s => s['@id'] === selectedObject['$metadata']["$model"])[0];
        // Initialize form data using properties from the selected schema and its extensions
        const properties = getAllProperties(selectedSchema, schemas);
        setObjectProperties(() => properties);
        const initialFormData = properties.reduce((acc, prop) => {
            acc[prop.name] = prop;
            prop.value = selectedObject[prop.name]
            return acc;
        }, {});
        setFormState(() => initialFormData);

        const getPropertyDisplayName = (key) => {
            var name = properties.filter((p) => p.name === key)[0].displayName;
            if(typeof name  === 'object')
            {
              debugger;
              name = name["en-US"]
            }
            return name;
          };
        formFields.current = Object.keys(initialFormData).map((key) => (
            <div key={key} className="property-field">
                <label className="property-label"> {getPropertyDisplayName(key)}</label>
                <input
                    className="property-input"
                    type="text"
                    name={key}
                />
            </div>
        ));
    }, [selectedObject]);

    useEffect(() => {
        inputRefs.current = Array.from(formRef.current.elements).reduce((acc, element) => {
            if (element.name) { // Only include elements with a name attribute\
                element.value = formState[element.name].value;
                acc[element.name] = element
            }
            return acc;
        }, {});


    }, [formState])

    const convertValue = (name, value) => {
        let convertedValue = value;
        let fieldSchema = objectProperties.filter(op => op["name"] === name)[0].schema;
        if (fieldSchema) {
            switch (fieldSchema) {
                case 'double':
                case 'float':
                case 'long':
                    convertedValue = parseFloat(value);
                    convertedValue = isNaN(convertedValue) ? 0 : convertedValue;
                    break;
                case 'integer':
                    convertedValue = parseInt(value, 10);
                    break;
                case 'boolean':
                    convertedValue = value === 'true';
                    break;
                case 'dateTime':
                    // Assuming ISO 8601 format; no conversion needed as it's already a string
                    break;
                // Handle additional schema types as needed
                default:
                    // For string and other unhandled types; no conversion needed
                    break;
            }
        }
        return convertedValue;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const data = Object.keys(inputRefs.current).reduce((acc, key) => {
            acc[key] = convertValue(key, inputRefs.current[key].value);
            return acc;
        }, {});
        var mergedObject = { ...selectedObject, ...data };
        delete mergedObject.children;
        console.log(mergedObject);

        const url = `${process.env.REACT_APP_VIA_OBJECT_API_BASE}/api/object/instance`;

        fetch(url, {
            method: 'PUT', // Specify the request method
            headers: {
                'Content-Type': 'application/json', // Specify the content type in the header
            },
            body: JSON.stringify(mergedObject)
        })
            .then(response => response.json()) // Parse JSON response into JavaScript object
            .then(data => {
                console.log('Success:', data); // Handle success
            })
            .catch((error) => {
                console.error('Error:', error); // Handle errors
            });

    };

    if (!selectedObject) {
        return <div className="editor-message">Please select an item to edit.</div>;
    }

    return (
        <form ref={formRef} onSubmit={handleSubmit} className="properties-editor">
            {formFields.current}
            <button type="submit" className="save-button">Save</button>
        </form>
    );
}


export default PropertiesEditor;
